import { SWRConfig } from 'swr'
import Nft from 'views/Nft'
// import Dashboard from 'views/Dashboard'

const IndexPage = () => {
  return (
    <SWRConfig>
      <Nft />
    </SWRConfig>
  )
}

IndexPage.chains = []

export default IndexPage
